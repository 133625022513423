.global__ {
  &flex-row {
    display: flex;
    flex-direction: row;
  }

  &flex-spacer {
    flex: 1 1 auto;
  }
}
