@mixin modal {
  background-color: #fff;
  padding: 5px;
  z-index: 200002;
  border-radius: 2px;
}

.modal-xl {
  @include modal;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
}

.modal-m {
  @include modal;
  width: 90%;
  max-width: 640px;
  max-height: calc(100% - 40px);
}

.modal-20rem {
  width: 20rem;
  height: 20rem;
}

.modal-right-button {
  float: right;
}
