@use "sass:map";
@use "sass:math";

$screen-xs-min: 0px;
$screen-xs-max: 576px;
$screen-s-min: 577px;
$screen-s-max: 768px;
$screen-m-min: 769px;
$screen-m-max: 992px;
$screen-l-min: 993px;
$screen-l-max: 1280px;
$screen-xl-min: 1281px;
$screen-xl-max: 16000px;

$breakpoints-max: (
  "xs": $screen-xs-max,
  "s": $screen-s-max,
  "m": $screen-m-max,
  "l": $screen-l-max,
  "xl": $screen-xl-max,
);
$breakpoints-min: (
  "xs": $screen-xs-min,
  "s": $screen-s-min,
  "m": $screen-m-min,
  "l": $screen-l-min,
  "xl": $screen-xl-min,
);
$breakpoint-names: xs, s, m, l, xl;

$columns: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;

$max-widths: 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000;

.col-container {
  padding: 0;
  margin: 0;
  list-style: none;

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;

  flex-wrap: wrap;

  width: 100%;
}

@mixin grid($cols) {
  padding: 4px 8px 4px 0px;
  flex-basis: math.div(100% * $cols, 12);
  box-sizing: border-box;

  &:nth-child(#{$cols}n) {
    padding-right: 0;
  }
}

@mixin col-auto-flex {
  padding: 0px 8px 2px 0px;
  box-sizing: border-box;
}

.col-auto-flex {
  @include col-auto-flex();
}

.col-auto-flex-last-right {
  @include col-auto-flex();
  flex-grow: 2;
  text-align: right;
}

@each $breakpoint in $breakpoint-names {
  $width-min: map.get($breakpoints-min, $breakpoint);
  $width-max: map.get($breakpoints-max, $breakpoint);
  @media only screen and (min-width: $width-min) {
    // hidden at given width
    .hidden-#{$breakpoint} {
      display: none !important;
    }
    @each $cols in $columns {
      .col-#{$breakpoint}-#{$cols} {
        @include grid($cols);
      }
      .col-#{$breakpoint}-auto-flex {
        @include col-auto-flex();
      }
    }
  }
}

@each $max-width in $max-widths {
  .max-width-#{$max-width} {
    max-width: #{$max-width}px;
  }
}

@each $width in $max-widths {
  .min-width-#{$width} {
    min-width: #{$width}px;
  }
}

@each $breakpoint in $breakpoint-names {
  $width: map.get($breakpoints-max, $breakpoint);
  @media only screen and (max-width: $width) {
    // Hidden all below and this
    .hidden-to-#{$breakpoint} {
      display: none !important;
    }
    @each $cols in $columns {
      .col-to-#{$breakpoint}-#{$cols} {
        @include grid($cols);
      }
    }
  }
}
