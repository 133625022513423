@import "~@fortawesome/fontawesome-free/css/all.css";
@import "../../node_modules/angular-calendar/css/angular-calendar.css";
@import "./colors.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0px;
  // font-family: Roboto, "Helvetica Neue", sans-serif;
}

.nowrap {
  white-space: nowrap;
}

a,
.clickable {
  cursor: pointer;
}

button.red {
  background-color: rgba(250, 0, 0, 0.8) !important;
  color: white !important;
}

button.green {
  background-color: rgba(0, 180, 0, 0.8) !important;
  color: white !important;
}

.alternate {
  background-color: var(--color-superlightgrey);
}

.margin-to-right {
  margin-right: var(--button-to-button-margin);
}

.margin-to-bottom {
  margin-right: var(--button-to-button-margin);
}

button.margin {
  margin-right: var(--button-to-button-margin);
  margin-bottom: var(--button-to-button-margin);
}

a {
  // padding: var(--link-padding);
  border-radius: var(--link-border-radius);
  text-decoration: none;
  // background-color: var(--link-background-color);
  color: var(--link-color);
}

a.visited {
  color: var(--link-color--visited);
}

@media (hover: hover) {
  a:hover {
    //text-decoration: underline;
    //background-color: var(--link-background-color--hover);
    //color: var(--link-color--hover);
    color: var(--tab-nav-color);
  }
}

textarea {
  max-height: 500px;
  min-height: 100px;
}

textarea.small {
  min-height: 50px;
}

.trumbowyg-box {
  border-radius: 4px;
}

.align-right {
  text-align: right;
}

.form-toggle {
  line-height: 3.2;
  margin: 0px 5px 0px 5px;
}

.busy-layer {
  display: none;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(200, 200, 200, 0.6);
  z-index: 99999;
  animation-duration: 0.5s;
  position: relative;
  .icon {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.busy-layer.busy-visible {
  display: block;
  position: fixed;
  visibility: visible;
  opacity: 1;
  animation-name: fadeIn;
  z-index: 999999999;
}

.busy-layer.busy-hidden {
  display: block;
  position: fixed;
  visibility: visible;
  opacity: 0;
  // This gives better style than animating, because this starts from current value and doesn't jump to 1 (if fadeIn takes less than animation duration)
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  //animation-name: fadeOut;
}

h2 {
  font-size: 16px;
  color: mat-color($primary, 700);
  border-bottom: 1px solid mat-color($primary, 500);
}

h3 {
  font-size: 15px !important;
  color: mat-color($primary, 700);
  margin-bottom: 4px !important;
}

.color-red {
  color: red;
}
