@import "@angular/material/theming";

$custom-typography: mat-typography-config(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $display-4: mat-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $display-3: mat-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $display-2: mat-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $display-1: mat-typography-level(34px, 40px, 400),
  $headline: mat-typography-level(24px, 32px, 400),
  $title: mat-typography-level(20px, 32px, 500),
  $subheading-2: mat-typography-level(16px, 28px, 400),
  $subheading-1: mat-typography-level(15px, 24px, 400),
  $body-2: mat-typography-level(14px, 24px, 500),
  $body-1: mat-typography-level(14px, 20px, 400),
  $caption: mat-typography-level(12px, 20px, 400),
  $button: mat-typography-level(14px, 14px, 500),
  // Line-height must be unit-less fraction of the font-size.
  $input: mat-typography-level(inherit, 1, 400),
);

@include mat-core($custom-typography);

$primary: mat-palette($mat-light-blue);
$accent: mat-palette($mat-green, A200, A100, A400);

// The warn palette is optional (defaults to red).
$warn: mat-palette($mat-red);

$hover-background: rgb(245, 245, 245);
// Create the theme object (a Sass map containing all of the palettes).
$theme: (
  "color": mat-light-theme($primary, $accent, $warn),
  "density": -3,
  "typography": $custom-typography,
);

$color-green: mat-color($accent, 700);

$color-lightgrey: rgb(200, 200, 200);

$color-superlightgrey: rgb(245, 245, 245);

:root {
  --color-lightgrey: #{$color-lightgrey};
  --color-superlightgrey: #{$color-superlightgrey};
  --color-yes: green;
  --color-no: red;
  --color-dontknow: rgb(150, 150, 150);
}
