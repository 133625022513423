$border-radius: 8px;

@mixin show-more-border {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

@mixin link-hover {
  @media (hover: hover) {
    cursor: pointer;
    color: var(--second-nav-color-hover) !important;
    background-color: var(--second-nav-background-color-hover) !important;
  }
}

// Defines colors for links (except for hover etc styles that are always the same)
@mixin colors($container-background-color, $link-color, $has-border: 0) {
  .nav-container--horizontal {
    background-color: $container-background-color;
  }
  .link {
    color: $link-color;
  }
  @if $has-border != 0 {
    .link:first-child {
      border-left: 1px solid $link-color;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    .link {
      border-top: 1px solid $link-color;
      border-bottom: 1px solid $link-color;
      border-right: 1px solid $link-color;
    }
    .link:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
  @if $has-border == 0 {
    .link {
      font-size: 110%;
      border-right: 1px solid $link-color;
    }
    .link:last-child {
      border-right: none;
    }
    .link:last-of-type {
      border-right: none;
    }
  }
}

// Main styles
.second-nav {
  // Full width container
  .nav-container {
    display: block;
    z-index: 100;
    position: relative;
  }
  @include colors(var(--second-nav-background-color), var(--second-nav-color));
}

.tab-nav {
  @include colors(var(--tab-nav-background-color), var(--tab-nav-color), 1);
  display: block;
  text-align: center;
  .nav-container {
    position: relative;
    z-index: 50;
    &--horizontal {
      background-color: white !important;
    }
  }
  // Here we have some specific style
  .show-more-button {    
    // height: 19px !important;
    right: 0px !important;
    border-right: 1px solid var(--show-more-close-background-color);
    border-top: 1px solid var(--show-more-close-background-color);
    border-bottom: 1px solid var(--show-more-close-background-color);
    mat-icon {
      height: 15px;
    }
  }
}

.tab-nav {
  margin-bottom: 20px;
}

// Dropdown styles
.dropdown {
  .nav-container {
    position: relative;
    &--standalone {
      position: relative;
    }
    &--horizontal {
      overflow: hidden;
      white-space: nowrap;
    }
    &--show-more {
      position: absolute;
      display: inline-block;
      box-shadow: 0px 2px 5px lightgrey;
      right: 0px;
      top: 0px;
      @include show-more-border;
      &--left {
        right: initial;
      }
    }
  }

  .link {
    display: inline-block;
    line-height: 150%;
    @media only screen and (max-width: 768px) {
      padding: 2px 10px;
    }
    @media only screen and (min-width: 769px) {
      padding: 4px 40px;
    }
    &--hidden {
      visibility: hidden;
    }
    &--active {
      background-color: var(--second-nav-background-color-active) !important;
      color: var(--second-nav-color-active);
    }
    &--web-portal {
      background-color: green;
      color: white;
    }
    &:hover {
      @include link-hover;
    }
    &--show-more {
      animation: dropdown-show-more 0.1s ease forwards;
      background-color: var(--show-more-background-color);
      color: var(--show-more-color);
      display: block;
      border-bottom: 1px solid var(--show-more-border-bottom-color);
      border-right: none;
      &:last-of-type {
        border-bottom: none;
        @include show-more-border;
      }
      &-close {
        text-align: center;
        background-color: var(--show-more-close-background-color);
        color: var(--show-more-close-color);
        padding: 0px !important;
        & .mat-icon {
          height: 10px;
        }
      }
      &:hover {
        @include link-hover;
      }
    }
  }

  .show-more-button {
    text-align: center;
    background-color: var(--show-more-close-background-color);
    color: var(--show-more-close-color);
    position: absolute !important;
    padding: 3px 4px;
    right: 0px;
    top: 0px;
    cursor: pointer;
    bottom: 0px;
    &:hover {
      @include link-hover();
    }
    &--open {
      @include link-hover();
    }
  }
}

@media only screen and (max-width: 480px) {
  .second-nav {
    .nav-container--show-more {
      left: 0px;
      right: 0px;
    }
    .link--show-more {
      text-align: center;
    }
  }
}

@keyframes dropdown-show-more {
  0% {
    padding: 0px 0px;
    font-size: 0%;
  }
  100% {
    padding: 8px 20px;
    font-size: 100%;
  }
}
