@import "./colors.scss";
@import "@angular/material/theming";

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($theme);

mat-form-field.full-width {
  display: block !important;
  // max-width: 400px;
}

.mat-form-field-infix {
  padding: 5px !important;
  max-width: 100% !important;
  width: initial !important;
  height: 17px;
}

.mat-form-field-label-wrapper {
  padding-top: 0px;
  padding-bottom: 0.75em;
}

mat-form-field:not(.mat-form-field-should-float) .mat-form-field-label {
  top: 1.2em !important;
}

.mat-content {
  justify-content: space-between;
}

.mat-content > mat-panel-title,
.mat-content > mat-panel-description {
  flex: 0 0 auto;
}

.mat-stroked-button {
  margin-top: 3px !important;
  line-height: 30px !important;
}

.row.actions-only .mat-stroked-button {
  margin-top: 0px !important;
  line-height: 20px !important;
}

.row.actions-only .mat-stroked-button.icon {
  padding-left: 2px !important;
  padding-right: 2px !important;
  min-width: initial !important;
  margin-right: 2px;
  .mat-icon {
    margin-top: 2px;
    height: 14px;
  }
}

// To show snackbar
.cdk-overlay-container {
  z-index: 1000000;
}

mat-card-header mat-icon {
  padding-top: 5px;
  font-size: 30px;
  color: var(--toolbar-background-color);
}

mat-card-header .action-icon {
  display: flex;
  flex-grow: 2;
  position: relative;
  & button {
    position: absolute;
    right: 0px;
    padding-left: 0px;
    font-size: 20px !important;
    line-height: 20px !important;
  }
  & mat-icon {
    margin-top: -7px;
    margin-left: -3px;
    font-size: 20px !important;
    text-align: right;
  }
}

mat-card-header mat-card-title {
  color: var(--tab-nav-color);
}

mat-card-title.no-subtitle {
  margin-top: 10px;
  padding-bottom: 20px;
}

mat-icon.rotate {
  transition: all 0.2s ease-out !important;
}

mat-icon.rotate180 {
  transform: rotate(180deg);
}
snack-bar-container.error {
  .mat-simple-snackbar-action {
    color: red;
  }
}

.mat-flat-button {
  line-height: 33px !important;
}

.icon-button {
  line-height: 40px !important;
  min-width: 0px !important;
  border-radius: 20px !important;
  margin-bottom: 0px;
  width: 40px;
  position: relative;
  height: 40px;
  span mat-icon {
    position: absolute;
    top: 13px;
    left: 14px;
    width: 14px !important;
    height: 14px;
    text-align: center;
  }
}

shared-loading-button.tiny button,
button.tiny {
  padding: 3px 0px 3px 0px;
  line-height: 18px !important;
}

.col-container mat-form-field {
  width: 100%;
}
